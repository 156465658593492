.App {
  text-align: center;
}

.App-logo {
  margin-top: 12vmin;
  height: 25vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.google-play {
  width: 30%;
}

.ios-store {
  width: 30%;
}

.App-logo {
  margin-top: 12vmin;
  margin-bottom: 6vmin;
  height: 25vmin;
  pointer-events: none;
}

.App-link {
  color: #e6ee9c;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
}
